import React from 'react';
import Home from '../home';
import Privacy from '../privacy';
import Terms from '../terms';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';

// 页面组件

function App() {
  return (
    <Router>
      <div>
        {/* <nav>
          <ul className="nav-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
            <li>
              <Link to="/terms">Terms</Link>
            </li>
          </ul>
        </nav> */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;