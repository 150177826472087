import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className="container">
      <h1>Terms of Service</h1>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By downloading, installing, or using the TTSaver app, you agree to these Terms of Service. 
          We may update these Terms from time to time, and any changes will be posted on this page with an updated "Effective Date".
        </p>
      </section>

      <section>
        <h2>2. License</h2>
        <p>
          We grant you a non-exclusive, non-transferable, revocable license to use the App in accordance with these Terms.
        </p>
      </section>

      <section>
        <h2>3. User Conduct</h2>
        <ul>
          <li>Do not violate any applicable laws or regulations.</li>
          <li>Do not use the App for unlawful or unauthorized purposes.</li>
          <li>Do not distribute malicious software or viruses.</li>
          <li>Do not attempt to reverse-engineer or modify the App.</li>
        </ul>
      </section>

      <section>
        <h2>4. Privacy Policy</h2>
        <p>
          Your use of the App is also governed by our Privacy Policy. Please review the Privacy Policy <Link to="/privacy">here</Link>.
        </p>
      </section>

      <section>
        <h2>5. Content</h2>
        <p>
          You are responsible for ensuring that your use of downloaded content complies with applicable copyright laws.
        </p>
      </section>

      <section>
        <h2>6. Termination</h2>
        <p>
          We may suspend or terminate your access to the App if you violate these Terms.
        </p>
      </section>

      <section>
        <h2>7. Disclaimers</h2>
        <p>
          The App is provided "as is" without warranties of any kind.
        </p>
      </section>

      <section>
        <h2>8. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, we are not liable for any damages arising from your use of the App.
        </p>
      </section>

      {/* <section>
        <h2>9. Governing Law</h2>
        <p>
          These Terms are governed by the laws of [Insert Jurisdiction]. Disputes will be resolved in [Insert Location].
        </p>
      </section> */}

      <section>
        <h2>9. Contact Us</h2>
        <p>If you have any questions, please contact us at <a href="mailto:support@tsmallin.com">support@tsmallin.com</a>.</p>
      </section>

      <footer>
        <p><strong>Effective Date:</strong> February 28, 2025</p>
      </footer>
    </div>
  );
};

export default Terms;
