import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="container">
      <h1>TT Saver</h1>

      <section>
        <h2>How to use our app?</h2>
        <ul>
          <li>Choose a video you want to save</li>
          <li>Click "Share" and then click "Copy link" button</li>
          <li>Open TT Saver</li>
          <li>Click "Download Video" button</li>
          <li>Video added to your gallery, enjoy :)</li>
        </ul>
      </section>

      <section>
        <h2>Important information</h2>
        <ul>
          <li>This app and its creators are not affiliated with Bytedance.</li>
          <li>Please respect the copyright of the CREATORS, do not distribute content without permission of the author</li>
          <li>We are not responsible for any infringement of intellectual property rights as a result of unauthorized reposting of videos or photos</li>
          {/* <li>This app doesn't allow users to save/download/listen to any music files. The feature of the app is only finding the artist/title/album information using song recognition</li> */}
        </ul>
      </section>

      <section>
        <h2>About us</h2>
        <ul>
          <li><a href="mailto:support@tsmallin.com">support@tsmallin.com</a></li>
          <li><Link to="/privacy">Privacy Policy</Link></li>
          <li><Link to="/terms">Terms of Use</Link></li>
        </ul>
      </section>

    </div>
  );
};

export default Home;
