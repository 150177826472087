import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';

// Privacy Policy Component
const Privacy = () => {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      <p>TTSaver (hereinafter referred to as "we" or "our") values your privacy and is committed to protecting your personal information. By using the TTSaver application, you agree to the collection, use, storage, and protection of your information as described in this Privacy Policy. Please read the following carefully.</p>
      
      <section>
        <h2>1. Information We Collect</h2>
        <ul>
          <li><strong>Device Information:</strong> Technical data such as device model, operating system version, unique device identifiers, etc.</li>
          <li><strong>App Usage Information:</strong> We collect analytics data regarding your usage habits, browsing history, and click data to improve our services.</li>
        </ul>
      </section>

      <section>
        <h2>2. How We Use Your Information</h2>
        <ul>
          <li>Provide and improve our services.</li>
          <li>Communicate with you for customer support or notifications.</li>
          <li>Analyze and optimize app performance and user experience.</li>
          <li>Provide personalized content and advertisements based on your needs and interests.</li>
        </ul>
      </section>

      <section>
        <h2>3. How We Protect Your Information</h2>
        <p>We employ industry-standard security measures to protect your personal information, including encryption technology and access controls. However, please be aware that no method of data transmission over the internet is completely secure, so there is always some risk involved in transmitting data.</p>
      </section>

      <section>
        <h2>4. Third-Party Service Providers</h2>
        <p>We may share your information with third-party service providers who assist us in processing your information. These providers will only access your information to the extent necessary to perform their services, and they are required to comply with our privacy policy.</p>
      </section>

      <section>
        <h2>5. Information Sharing and Disclosure</h2>
        <ul>
          <li><strong>Legal Requirements:</strong> In response to legal processes such as court orders or government requests.</li>
          <li><strong>Protection of Rights:</strong> If we believe your actions violate our terms of use or policies, we may take necessary actions to protect our legal rights.</li>
        </ul>
      </section>

      {/* <section>
        <h2>6. User Control and Choices</h2>
        <p>You can control the app’s access to certain permissions, including location, camera, microphone, etc., through your device settings. If you choose to disable certain permissions, some features of the application may be affected.</p>
      </section> */}

      {/* <section>
        <h2>7. Children’s Privacy</h2>
        <p>Our app is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children. If we become aware that we have unintentionally collected personal information from children under 13, we will take steps to delete that data as soon as possible.</p>
      </section> */}

      <section>
        <h2>6. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our services or legal requirements. Any changes will be posted on this page with an updated date. Please check this page periodically for updates.</p>
      </section>

      <section>
        <h2>7. Contact Us</h2>
        <p>If you have any questions or concerns regarding this Privacy Policy, or if you need more information, please contact us:</p>
        <p>Email: <a href="mailto:support@tsmallin.com">support@tsmallin.com</a></p>
        <p>Official Website: <Link to="/">ttsaver.tsmallin.com</Link></p>
      </section>

      <footer>
        <p><strong>Effective Date:</strong> February 28, 2025</p>
      </footer>
    </div>
  );
};

export default Privacy;
